import React, { useState } from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import LayoutDirectory from "components/layoutDirectory"
import NextRelease from "components/nextRelease"
import MiniAlbum from "components/album/miniAlbum"
import { StyledAlbumGrid } from "components/styledAlbumGrid"
import DirectoryHeader from "components/directory/header"
import AwardAnnouncement from "../components/svg/AwardAnnouncement"

const ListenPage = ({ data }) => {
  const [albums, setAlbums] = useState(data.albums.edges)
  const [order,setOrder] = useState(`dateDesc`)

  return (
    <>
    <LayoutDirectory>
      <Helmet>
        <title>Albums | {data.site.siteMetadata.title}</title>
      </Helmet>
      <DirectoryHeader
        title={<><strong>All</strong> Albums</>}
        order={order}
        setOrder={setOrder}
        albums={albums}
        setAlbums={setAlbums}
      />
      <StyledAlbumGrid>
        {albums.map(({ node }) =>
          <MiniAlbum key={node.id} albumData={node} />
        )}
      </StyledAlbumGrid>
      <NextRelease extraMargin={true} />
    </LayoutDirectory>
    <AwardAnnouncement />
    </>
  )
}

export default ListenPage

export const query = graphql`
    query {
        albums: allDirectusAlbum(filter: {wave: {in: ["1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","20","21","22","23","24","25","26","27","28","29","30","31","32","33","34","35"]}}, sort: {fields: created_on, order: DESC}) {
          edges {
            node {
              ...AlbumExcerpt
            }
          }
        }
        site {
            id
            siteMetadata {
                title
            }
        }
    }
`
